
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.v-main.not-allowed,
.v-main.main-background.not-allowed {
    background-color: white !important;
    background-image: url('@/assets/img/403/full.svg');
    background-position: center;
    background-size: contain;
}
